import React from 'react';
import Item from './Item';
import styles from './styles.module.sass';

export default ({ items, page }) => (
  <div className={styles.subContainer}>
    {items.slice().reverse().map((el, key) => (
      <Item key={key}
        inSub
        selected={el.name === page}
        {...el}/>
    ))}
  </div>
)