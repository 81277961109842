import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { capitalize } from '@material-ui/core/utils';
import styles from './styles.module.sass';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'div'
}

const Variant = ({ variant, className, children }) => React.createElement(
  variantMapping[variant],
  { className },
  children
);

const Typography = ({
  children,
  color,
  weight,
  align,
  variant,
  className = ''
}) => (
  <Variant variant={variant}
    className={clsx(
      styles[variant],
      styles[align],
      styles['color' + capitalize(color)],
      styles['weight' + capitalize(weight)],
      className
    )}>
    {children}
  </Variant>
)

Typography.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  color: PropTypes.oneOf(['white', 'light', 'highlight', 'main', 'dark', 'secondary', 'secondaryDark', 'alarm']),
  weight: PropTypes.oneOf(['light', 'semilight', 'regular', 'bold']),
  variant: PropTypes.string,
};

Typography.defaultProps = {
  align: 'center',
  color: 'main',
  weight: 'regular'
};

export default Typography;