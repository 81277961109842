import i18n, { locale } from '../../../locale';

export default [{
    heading: i18n('Platform'),
    pages: [{
      name: i18n('Platform overview'),
      url: i18n('_url:platform')
    }, {
      name: i18n('Data sources'),
      url: i18n('_url:platform')
    }, {
      name: i18n('SaaS tools'),
      url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`
    }]
  },{
  heading: i18n('Services'),
  pages: [{
    name: 'Fleet',
    url: `${i18n('_url:services')}#${i18n('_hash:fleet')}`
  }, {
    name: 'Vehicle Care',
    url: `${i18n('_url:services')}#${i18n('_hash:vehicle-care')}`
  }, {
    name: 'Drive',
    url: `${i18n('_url:services')}#${i18n('_hash:driver-app')}`
  }]
}, {
  heading: i18n('Industries'),
  pages: [{
    name: i18n('Driver'),
    url: `${i18n('_url:drivers')}`
  }, {
    name: i18n('Fleet manager'),
    url: `${i18n('_url:fleet-management')}`
  }, {
    name: i18n('Leasing'),
    url: `${i18n('_url:leasing')}`
  }, {
    name: i18n('Repair shop'),
    url: `${i18n('_url:repair-shop')}`
  }, {
    name: i18n('Dealership'),
    url: `${i18n('_url:dealership')}`
  }, {
    name: i18n('Use cases'),
    url: `${i18n('_url:use-cases')}`
  }]
}, {
  heading: i18n('About us'),
  pages: [{
    name: i18n('The company'),
    url: `${i18n('_url:about-us')}`
  }, {
    name: i18n('Vision'),
    url: `${i18n('_url:about-us')}#${i18n('_hash:vision')}`
  }, {
    name: i18n('Partners'),
    url: `${i18n('_url:about-us')}#${i18n('_hash:partners')}`
  }, {
    name: i18n('Join us'),
    external: true,
    url: `https://thehub.io/startups/obi-plus-aps`
  }]
}, {
  heading: i18n('Resources'),
  pages: [{
    name: i18n('The blog'),
    url: `${i18n('_url:blog')}`
  }, {
    name: i18n('Terms'),
    external: true,
    url: `${i18n('_url:privacy-policy')}`
  }, {
    name: i18n('Privacy'),
    external: true,
    url: `${i18n('_url:privacy-policy')}`
  }, 
  // {
  //   name: i18n('Product tour'),
  //   url: `${i18n('_url:sign-up')}`
  // }, 
  ...(locale === 'da' ? [{
    name: i18n('Fleet ROI calculator'),
    url: `${i18n('_url:fleet-roi')}`
  }, {
    name: i18n('Logbook ROI calculator'),
    url: `${i18n('_url:logbook-roi')}`
  }] : [])
  ]
}]