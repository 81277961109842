import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import clsx from 'clsx';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        blog: imageSharp(fluid: {originalName: {eq: "Footer-Blog-gnd.png"}}) {
          fluid(maxWidth: 483, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ blog }) => (
      <Grid item container xs={12} sm={12} md={5} lg={4} xl={4}
        justify='flex-end'
        alignItems='center'
        className={clsx(
          styles.container,
          // narrow ? styles.containerNarrow : styles.containerWide
        )}>
        <Img imgStyle={{
            objectFit: 'contain',
          }}
          objectPosition='center left'
          objectFit='contain'
          className={styles.imageContainer}
          fluid={blog.fluid}
          alt='Ser blog'/>
        <Link to={i18n('_url:blog')} className={styles.link}>
          <Typography variant='h4' className={styles.text} color='white'
            align='left'>
            {i18n('See our blog')}
          </Typography>
          <div className={styles.arrowContainer}>
            <div className={styles.arrow}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.1 24"
                height='100%'
                width="100%">
                <defs><style>{'.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}'}</style></defs>
                <path className='cls-1' d="M21.1 1.1L32 12 21.1 23M0 12h32"/>
              </svg>
            </div>
          </div>
        </Link>
      </Grid>
    )}/>
);
