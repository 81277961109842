import React from 'react';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '../../../Typography';
import Dropdown from './Dropdown';
import MegaMenu from './MegaMenu';
import styles from './styles.module.sass';

export default ({
  url,
  className,
  selected = false,
  text,
  drop,
  megaMenu,
  inSub = false,
  onClick = null,
  callToAction = false,
  Wrapper = ({ children }) => url ? (
      <GatsbyLink to={url}
        className={clsx(
          styles.anchorContainer,
          inSub ? styles.anchorContainerSub : ''
        )}>
        {children}
      </GatsbyLink>
    ) : (
      <div className={styles.anchorContainer}>
        {children}
      </div>
    ),
  Inner = () => (
    <>
      <Typography variant='subtitle1'
        align='left'
        className={clsx(
          styles.anchor,
          selected ? styles.anchorSelected : '',
          callToAction ? styles.anchorCtoa : ''
        )}>
        {text}
      </Typography>
      {selected && url
      ? <div className={clsx(
          styles.underline,
          callToAction ? styles.underlineCallToAction : ''
        )}/>
      : null}
    </>
  )
}) => {
  const [ expanded, setExpanded ] = React.useState(selected);

  return (
    // In the keys.js file you can set urls to 'disabled' in order to disable specific menu item in specific language
    url !== 'disabled' ? <>
      <MenuItem onClick={drop || megaMenu
        ? () => setExpanded(!expanded)
        : onClick}
        className={clsx(
          styles.menuItem,
          inSub ? styles.subMenuItem : '',
          callToAction ? styles.menuItemCtoa : '',
          className
        )}>
        <Wrapper>
          <Inner/>
        </Wrapper>
      </MenuItem>
      {drop
      ? <Dropdown items={drop}
          expanded={expanded}/>
      : null}
      {megaMenu
      ? <MegaMenu menu={megaMenu}
        expanded={expanded || selected}/>
      : null}
    </>
    : null
  )
};