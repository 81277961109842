import React from 'react';
import clsx from 'clsx';
import Typography from '../../../Typography';
import styles from './styles.module.sass';

export default ({ text, selected, callToAction }) => (
  <div className={styles.anchorSubContainer}>
    <Typography align='left' variant='subtitle1'>
      <span className={clsx(
          styles.anchor,
          selected ? styles.anchorSelected : '',
          callToAction ? styles.anchorCtoa : ''
        )}>
        {text}
      </span>
    </Typography>
  </div>
);
