import React from 'react';
import { Grid } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import Typography from '../../../Typography';
import styles from './styles.module.sass';

export default ({ menu }) => (
  <div className={styles.megaMenu} style={{ width: `${menu.menuWidth}px`}}>
      <Grid container>
        {menu.columns.map((column, index) => (
            <Grid item xs={12} sm={6} md={column.width} xl={column.width} className={styles.megaMenuColumn} key={`column-${index}`}>
                <div className={styles.megaMenuColumnTitleWrap}>
                    <Typography variant='h4' align='left' className={styles.megaMenuColumnTitle}>
                        {column.title}
                    </Typography>
                    <Typography variant='subtitle2' align='left' className={styles.megaMenuColumnSubTitle}>
                        {column.subTitle}
                    </Typography>
                </div>
                <Grid container>
                {column.items.map((item, subIndex) => (
                  <Grid item xs={12} sm={6} md={item.width} xl={item.width} key={`item-${subIndex}`}>
                    {item.blank 
                        ? <a href={item.url} target="_blank" rel="noreferrer" className={styles.megaMenuItem}>
                            <Typography variant='h4' align='left' className={styles.megaMenuItemTitle}>
                                {item.title}
                            </Typography>
                            <Typography variant='subtitle2' align='left' className={styles.megaMenuItemSubTitle}>
                                {item.subTitle}
                            </Typography>
                        </a>
                        : <GatsbyLink to={item.url} className={styles.megaMenuItem}>
                            <Typography variant='h4' align='left' className={styles.megaMenuItemTitle}>
                                {item.title}
                            </Typography>
                            <Typography variant='subtitle2' align='left' className={styles.megaMenuItemSubTitle}>
                                {item.subTitle}
                            </Typography>
                        </GatsbyLink>
                    }
                  </Grid>
                ))}
                </Grid>
            </Grid>
        ))}

      </Grid>
    
  </div>
)