import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';

// More info about the menu can be found in the README
export default [{
  name: sitemap._,
  text: i18n('Main'),
  url: '/#' + i18n('_hash:main')
}, {
  name: sitemap.platform,
  text: i18n('Platform'),
  url: i18n('_url:platform'),
  megaMenu: {
    columnCount: 2,
    menuWidth: 1100,
    columns: [
      {
        title: i18n('Platform overview'),
        subTitle: i18n('End-to-end solution'),
        width: 4,
        items: [
          {
            name:sitemap.platform,
            title: i18n('Data sources'),
            subTitle: i18n('Connecting to millions of vehicles'),
            url: i18n('_url:platform'),
            width: 12,
          },
          {
            name:sitemap.platform,
            title: i18n('Seamless backend'),
            subTitle: i18n('Designed to scale and integrate'),
            url: i18n('_url:platform'),
            width: 12,
          },
          {
            name:sitemap.platform,
            title: i18n('Ready to use services'),
            subTitle: i18n('From technology to customer adoption'),
            url: i18n('_url:platform'),
            width: 12,
          }
        ],
      },
      {
        title: i18n('SaaS tools'),
        subTitle: i18n('Automating key processes'),
        width: 8,
        items: [
          {
            name:sitemap.platform,
            title: i18n('Digital agreements'),
            subTitle: i18n('Simplified data management'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('User management'),
            subTitle: i18n('Flexibility to adapt any setup'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Business Intelligence'),
            subTitle: i18n('Report, analyse and improve'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('In-product onboarding'),
            subTitle: i18n('Automating onboarding flows'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Subscription management'),
            subTitle: i18n('Track usage and manage billing'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Smart assistant'),
            subTitle: i18n('Reducing administration tasks'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Access management'),
            subTitle: i18n('Flexible administration'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
          {
            name:sitemap.platform,
            title: i18n('Support overview'),
            subTitle: i18n('Control over support operations'),
            url: `${i18n('_url:platform')}#${i18n('_hash:saas-tools')}`,
            width: 6,
          },
        ],
      },
    ]
  }
}, {
  name: sitemap.services,
  text: i18n('Services'),
  url: i18n('_url:services'),
  megaMenu: {
    columnCount: 1,
    menuWidth: 500,
    columns: [
      {
        title: i18n('Ready to use services'),
        subTitle: i18n('Be connected tomorrow'),
        width: 12,
        items: [
          {
            name:sitemap.services,
            title: i18n('Vehicle care'),
            subTitle: i18n('Digital link to customers and vehicles'),
            url: `${i18n('_url:services')}#${i18n('_hash:vehicle-care')}`,
            width: 12,
          },
          {
            name:sitemap.services,
            title: i18n('Fleet'),
            subTitle: i18n('Modern fleet management'),
            url: `${i18n('_url:services')}#${i18n('_hash:fleet')}`,
            width: 12,
          },
          {
            name:sitemap.services,
            title: i18n('Drive'),
            subTitle: i18n('Car owner\'s best friend'),
            url: `${i18n('_url:services')}#${i18n('_hash:driver-app')}`,
            width: 12,
          }
        ],
      }, 
    ]
  }
},{
  name: sitemap.industries,
  text: i18n('Industries'),
  megaMenu: {
    columnCount: 2,
    menuWidth: 1100,
    columns: [
      {
        title: i18n('By profile'),
        subTitle: i18n('Learn more about our services'),
        width: 4,
        items: [
          {
            name:sitemap.driver._,
            title: i18n('Driver'),
            subTitle: i18n('Your car\'s best friend'),
            url: i18n('_url:drivers'),
            width: 12,
          },
          {
            name: sitemap.fleet._,
            title: i18n('Fleet manager'),
            subTitle: i18n('Track, maintain and manage vehicles'),
            url: i18n('_url:fleet-management'),
            width: 12,
          },
          {
            name: sitemap.repairShop,
            title: i18n('Repair shop'),
            subTitle: i18n('Increase customer retention'),
            url: i18n('_url:repair-shop'),
            width: 12,
          },
          {
            name:sitemap.leasing,
            title: i18n('Leasing'),
            subTitle: i18n('Simplify vehicle management'),
            url: i18n('_url:leasing'),
            width: 12,
          },
          {
            name:sitemap.dealership,
            title: i18n('Dealership'),
            subTitle: i18n('Increase the after sales value'),
            url: i18n('_url:dealership'),
            width: 12,
          }
        ],
      },
      {
        title: i18n('By use case'),
        subTitle: i18n('Exploring new exciting spaces'),
        width: 8,
        items: [
          {
            name:sitemap.useCases,
            title: i18n('Remote vehicle monitoring'),
            subTitle: i18n('Direct link to vehicles 24/7'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:remote-monitoring')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Fleet management'),
            subTitle: i18n('More efficient daily operations'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:fleet-management')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Preventive maintenance'),
            subTitle: i18n('Schedule and automate maintenance'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:preventive-maintenace')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Green driving'),
            subTitle: i18n('Identify areas to improve'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:green-driving')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Digital communication'),
            subTitle: i18n('Use video or chat to communicate'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:digital-communication')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Customer satisfaction'),
            subTitle: i18n('New tools to keep the customer satisfied'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:customer-satisfaction')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('Digital documentation'),
            subTitle: i18n('Store and view vehicle\'s history digitally'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:digital-documentation')}`,
            width: 6,
          },
          {
            name:sitemap.useCases,
            title: i18n('EV ownership'),
            subTitle: i18n('Adapting to the specific needs'),
            url: `${i18n('_url:use-cases')}#${i18n('_hash:ev-ownership')}`,
            width: 6,
          },
        ],
      }, 
    ]
  }
}, {
  name: sitemap.aboutUs,
  text: i18n('About us'),
  url: i18n('_url:about-us')
}, {
  name: sitemap.signUp,
  text: i18n('Get started'),
  url: i18n('_url:sign-up'),
  callToAction: true 
}];