import React from 'react';
import { Grid } from '@material-ui/core';
import Twitter from './Twitter';
import YouTube from './YouTube';
import LinkedIn from './LinkedIn';
import Email from './Email';
import Facebook from './Facebook';
import styles from './styles.module.sass';

export default ({ name, url, noTarget = true }) => (
  <Grid item className={styles.iconContainer}>
    <a title={`Kontakt os: ${name}`} 
      href={url} 
      target={noTarget ? '_blank' : ''} rel="noopener noreferrer">
      {name === 'twitter' ? <Twitter/>
      : name === 'youtube' ? <YouTube/>
      : name === 'email' ? <Email/>
      : name === 'linked-in' ? <LinkedIn/>
      : name === 'facebook' ? <Facebook/>
      : null}
    </a>
  </Grid>
);